<template>
  <div id="contactFormComponent">
    <form v-if="!getFeedback" :class="getPending ? 'disabled-element' : ''" @submit.prevent="sendMessage" class="form mb-5" id="contactForm">
      <p v-if="contactForm.localError" class="form__error mr-1">{{ contactForm.localError }}</p>
      <p v-if="getError" class="form__error">{{ getError }}</p>
      <div class="form-group">
        <label class="form__label" for="name">{{ this.name_label }}</label>
        <input v-model.trim="contactForm.name" @blur="$v.contactForm.name.$touch()" :class=" [{'input-error' : $v.contactForm.name.$error }, getPending ? 'disabled-element' : '']" type="text" class="form__input form-control" id="name" placeholder="John Doe" autocomplete="off" :disabled="getPending">
        <div v-if="$v.contactForm.name.$error">
          <p v-if="!$v.contactForm.name.required" class="form__error">Name is required</p>
          <p v-else-if="!$v.contactForm.name.maxLength" class="form__error">Name is limited to 100 characters</p>
        </div>
      </div>
      <div class="form-group">
        <label class="form__label" for="email">{{ this.email_label }}</label>
        <input v-model.trim="contactForm.email" @blur="$v.contactForm.email.$touch()" :class=" [{'input-error' : $v.contactForm.email.$error }, getPending ? 'disabled-element' : '']" type="email" class="form__input form-control" id="email" placeholder="john.doe@example.com" autocomplete="off" :disabled="getPending">
        <div v-if="$v.contactForm.email.$error">
          <p v-if="!$v.contactForm.email.required" class="form__error">e-mail address is required</p>
          <p v-else-if="!$v.contactForm.email.email" class="form__error">e-mail address is invalid</p>
          <p v-else-if="!$v.contactForm.email.maxLength" class="form__error">e-mail address is limited to 100 characters</p>
        </div>
      </div>
      <div class="form-group">
        <label class="form__label" for="message">{{ this.message_label }}</label>
        <textarea v-model.trim="contactForm.message" @blur="$v.contactForm.message.$touch()" :class=" [{'input-error' : $v.contactForm.message.$error }, getPending ? 'disabled-element' : '']" type="text" class="form__input form__input--textarea form-control" id="message" placeholder="Your message" autocomplete="off" :disabled="getPending"></textarea>
        <div v-if="$v.contactForm.message.$error">
          <p v-if="!$v.contactForm.message.required" class="form__error">Message is required</p>
          <p v-else-if="!$v.contactForm.message.maxLength" class="form__error">Message is limited to 3000 characters</p>
        </div>
      </div>
      <div class="form-group">
        <p-check :disabled="getPending" class="p-svg p-curve p-jelly" color="success" v-model="contactForm.termsConsent">
          <!-- svg path -->
          <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
            <path
              d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
              style="stroke: white;fill:white"
            ></path>
          </svg>
        {{ this.consent_text }}
        </p-check>
        <div v-if="$v.contactForm.termsConsent.$error">
          <p v-if="!$v.contactForm.termsConsent.acceptTermsConsent" class="form__error">Consent is required</p>
        </div>
      </div>
      <div class="form-group">
        <div class="recaptcha">
          <vue-recaptcha
            @verify="markRecaptchaAsVerified"
            :sitekey="this.contactForm.recaptchaSitekey"
          ></vue-recaptcha>
        </div>
        <div v-if="$v.contactForm.recaptcha.$error">
          <p v-if="!$v.contactForm.recaptcha.verifyRecaptchay" class="form__error">reCAPTCHA is required</p>
        </div>
      </div>
      <button type="submit" class="btn main-button main-button--wider mt-4 mb-5" :class="getPending ? 'disabled-element' : ''" :disabled="getPending">{{ this.submit_button_text }}</button>
    </form>
    <div v-else class="text-left">
      <p class="main-title">{{ this.feedback_text }}</p>
    </div>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import { mapGetters } from 'vuex'
import PrettyCheck from 'pretty-checkbox-vue/check'
import { email, required, maxLength, sameAs } from 'vuelidate/lib/validators'
import MoveTo from 'moveto'
export default {
  name: 'ContactForm',
  props: {
    name_label: {
      type: String,
      required: false
    },
    email_label: {
      type: String,
      required: false
    },
    message_label: {
      type: String,
      required: false
    },
    consent_text: {
      type: String,
      required: false
    },
    submit_button_text: {
      type: String,
      required: false
    },
    feedback_text: {
      type: String,
      required: false
    }
  },
  components: {
    'p-check': PrettyCheck,
    VueRecaptcha
  },
  data: () => {
    return {
      contactForm: {
        name: '',
        email: '',
        message: '',
        termsConsent: false,
        recaptcha: false,
        recaptchaSitekey: process.env.VUE_APP_RECAPTCHA_KEY,
        localError: ''
      }
    }
  },
  validations: {
    contactForm: {
      name: {
        required: required,
        maxLength: maxLength(100)
      },
      email: {
        required: required,
        email: email,
        maxLength: maxLength(100)
      },
      message: {
        required: required,
        maxLength: maxLength(3000)
      },
      termsConsent: {
        acceptTermsConsent: sameAs(() => true)
      },
      recaptcha: {
        verifyRecaptchay: sameAs(() => true)
      }
    }
  },
  methods: {
    markRecaptchaAsVerified () {
      this.contactForm.recaptcha = true
    },
    sendMessage (event) {
      this.$store.dispatch('setPending', { pending: true })
      event.preventDefault()
      this.$v.contactForm.$touch()
      if (!this.$v.contactForm.$invalid) {
        this.$store.dispatch('sendMessage', { name: this.contactForm.name, email: this.contactForm.email, message: this.contactForm.message })
          .then(() => {
            this.scrollToTargetElement('contactFormComponent')
            this.$store.dispatch('setPending', { pending: false })
          })
          .catch(() => {
            this.resetPasswordForm.localError = 'An error occurred, please try again later'
            this.$store.dispatch('setPending', { pending: false })
          })
      } else {
        this.scrollToTargetElement('contactFormComponent')
        this.$store.dispatch('setPending', { pending: false })
        this.contactForm.localError = 'Form validation failed'
      }
    },
    scrollToTargetElement (targetElement) {
      const moveTo = new MoveTo({
        tolerance: 100,
        duration: 1000
      })
      const target = document.getElementById(targetElement)
      moveTo.move(target)
    }
  },
  computed: {
    ...mapGetters([
      'getError',
      'getPending',
      'getFeedback'
    ])
  },
  beforeCreate () {
    this.$store.dispatch('clearError')
    this.$store.dispatch('clearFeedback')
  },
  mounted () {
    const recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute(
      'src',
      'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit&hl=en'
    )
    document.head.appendChild(recaptchaScript)
  }
}
</script>
